<template>
    <h2>Info</h2>

    <div class="grid">
        <div class="col">
    <div>
        <InputMask type="text" mask="999.999.999-99" v-model="taxIdNumber" placeholder="CPF" />
    </div>

    <div>
        <Calendar v-model="birthDate" pattern="yyyy-MM-dd" placeholder="Data de Nascimento" />
    </div>

    <div>
        <InputText type="text" v-model="institutionName" placeholder="Conselho" />
    </div>

    <div>
        <InputText type="text" v-model="licenseNumber" placeholder="Registro" />
    </div>

    <div>
        <Button type="button" label="Enviar" @click="updateInfo" />
    </div>

        </div>
        <div class="col">
            <img :src="userPicture" />

            <input ref="file" v-on:change="getFile($event)"  type="file" />
            <Button label="Upload" @click='upload' />

        </div>
    </div>


<hr />
<DataTable :value="docList">
    <Column header="Id" field="id" />
    <Column header="Tipo" field="fileType" />
    <Column header="Description" field="description" />
    <Column header="Documento">
        <template #body="slotProps">
            <a :href="`https://storage.googleapis.com/ommed-doc/${slotProps.data.url}`">Download</a>
        </template>
    </Column>
</DataTable>
<hr />

<InputText v-model="docDescription" placeholder="Descrição" />
<InputText v-model="docType" placeholder="Tipo" />
<input ref="docfile" v-on:change="getDocFile($event)" type="file" />
<Button label="Upload" @click='docUpload' />

</template>

<script>

import { ref } from 'vue';
import { UpdateInfoRequest } from '../../core/grpc/generated/professionalProfile_pb';
import profileService from '../../core/services/professionalProfileService';
import moment from 'moment';
import axios from 'axios';
import useStore from '../../../store';

export default {
    setup() {

        const fileApiUrl = process.env.VUE_APP_FILE_API_URL;
        const bucketUrl = process.env.VUE_APP_BUCKET_URL;

        let taxIdNumber = ref('');
        let birthDate = ref('');
        let institutionName = ref('');
        let licenseNumber = ref('');
        let userPicture = ref('/assets/images/default-profile.jpg');

        (async () => {
            const response = await profileService.getInfo();

            if (response.success == true) {
                const data = JSON.parse(response.data);
                taxIdNumber.value = data.taxIdNumber;
                if (data.birtDate) {
                    birthDate.value = moment(data.birtDate).format('YYYY-MM-DD');
                }
                institutionName.value = data.institutionName;
                licenseNumber.value = data.licenseNumber;

                if (data.pictureUrl) {
                    userPicture.value = `${bucketUrl}/${data.pictureUrl}` ;
                }

                console.log(moment(data.birthDate));
            }

            console.log(response);
        })();

        const updateInfo = async () => {
            let request = new UpdateInfoRequest();
            request.setTaxidnumber(taxIdNumber.value);

            console.log(birthDate.value);

            if (birthDate.value instanceof Date) {
                const date = moment(birthDate.value).format('YYYY-MM-DD');
                request.setBirthdate(date);

            }
            request.setInstitutionname(institutionName.value);
            request.setLicensenumber(licenseNumber.value);

            const response = await profileService.updateInfo(request);
            console.log(response);
        }

        let file = ref(null)
        let selectedFile = ref(null);
        const getFile = (event) => {
            selectedFile.value = event.target.files[0];
            console.log(selectedFile.value);
        }        

        const upload = () => {
            let formData = new FormData();
            formData.append('file', selectedFile.value);

            const token = localStorage.getItem('token');

            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${token}`
                }
            };

            axios.post(`${fileApiUrl}/professional/picture`, formData, config)
                .then(r => {
                    console.log(r.data);
                    userPicture.value = `${bucketUrl}/${r.data.url}`;
                })
                .catch(err => {
                    console.log(err.data)
                });
        }



        let docFile = ref(null)
        let docDescription = ref('');
        let docType = ref('');
        let selectedDocFile = ref(null);
        const getDocFile = (event) => {
            selectedDocFile.value = event.target.files[0];
            console.log(selectedDocFile.value);
        }        

        const docUpload = async () => {
            let formData = new FormData();
            formData.append('file', selectedDocFile.value);
            formData.append('description', docDescription.value);

            const metadata = {
                type: docType.value
            };
            console.log(JSON.stringify(metadata));
            formData.append('metadata', JSON.stringify(metadata));

            const token = localStorage.getItem('token');

            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${token}`
                }
            };

            try {
                const response = await axios.post(`${fileApiUrl}/professional/document`, formData, config);
                console.log(response.data);
                await listDocs();
            }
            catch (err) {
                console.log(err.data)
            }
        }

        let docList = ref([]);
        const listDocs = async () => {
            const response = await profileService.listDocuments();
            if (response.success) {
                const data = JSON.parse(response.data);
                console.log(data.length);
                docList.value = data;
            }
            console.log(response);
        }

        (async () => { await listDocs(); })();

        (() => {
            const metadata = {
                type: docType.value
            };
            console.log(JSON.stringify(metadata));
        })();

        return {
            taxIdNumber, birthDate, institutionName, licenseNumber,
            updateInfo,
            upload,
            file, getFile,
            userPicture,

            docList,
            docFile, selectedDocFile, docDescription, docType, getDocFile, docUpload
        }        
    },
}
</script>

<style scoped>

</style>